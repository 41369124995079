import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import BgImage from "../../images/mens.jpg"
import Layout from "../../components/layoutSurpriseCustom"
import PageHeaders from "../../components/pageHeaders"
import SEO from "../../components/seo"

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
`

const IndexPage = () => (
  <Layout ImgSrc={BgImage} MainText="Men @ Cross Church">
    <SEO title="Men @ Cross Church" />
    <PageHeaders
      css={css`
        display: flex;
      `}
    >
      Men @ Cross Church
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      Men’s Ministry is the active pursuit of men in order to connect them to
      God, His Word, and other men for the purpose of winning, growing, and
      training God’s man in Christ.
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      Move the Chains - Men Event
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      <a href="https://crossaz.infellowship.com/UserLogin/Index?ReturnUrl=%2fForms%2f458413">Sign up</a> for the upcoming Move the Chains men event.
      <br />
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 6rem;
      `}
    >
      Contact Us for Additional Info
    </PageHeaders>
    <iframe
      src="https://cccares.formstack.com/forms/nextsteps"
      title="Connect - Cross Church"
      width="100%"
      height="1200px"
      frameBorder="0"
    ></iframe>
  </Layout>
)

export default IndexPage
